<template>
    <div class="block_text_editor">
        <div class="block_text_editor_header">
            <button @click="formatText('bold')"><b>B</b></button>
            <button @click="formatText('italic')"><i>I</i></button>
            <button @click="formatText('underline')"><u>U</u></button>
            <button @click="insertEmoji">😊</button>
            <button @click="formatText('insertOrderedList')">1.</button>
            <div class="char-count">{{ length }}/{{ maxLength }}</div>
        </div>

        <div v-if="showEmojiPicker" class="emoji-picker">
            <button v-for="emoji in emojis" :key="emoji" @click="addEmoji(emoji)">{{ emoji }}</button>
        </div>

        <div ref="editor" class="block_text_editor_content" contenteditable="true" @input="onInput"
            @keydown="handleKeyDown" @mouseup="saveSelection" @keyup="saveSelection"></div>
    </div>
</template>

<script>
export default {
    props: ["maxLength", "type", "index", "original"],
    emits: ["updateValue"],
    data() {
        return {
            errorMessage: "",
            savedRange: null,
            editor: null,
            previousContent: "",
            length: 0,
            showEmojiPicker: false,
            emojis: [
                '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇',
                '🙂', '🙃', '😉', '😌', '😍', '🥰', '😘', '😗', '😙', '😚',
                '😋', '😜', '😝', '😛', '🤑', '🤗', '🤭', '🤫', '🤔', '🤐',
                '🤨', '😐', '😑', '😶', '😏', '😒', '🙄', '😬', '🤥', '😌',
                '😔', '😪', '🤤', '😴', '😷', '🤒', '🤕', '🤢', '🤮', '🤧',
                '🥵', '🥶', '🥴', '😵', '🤯', '🤠', '🥳', '😎', '🤓', '🧐',
                '😕', '😟', '🙁', '☹️', '😮', '😯', '😲', '😳', '🥺', '😦',
                '😧', '😨', '😰', '😥', '😢', '😭', '😱', '😖', '😣', '😞',
                '😓', '😩', '😫', '😤', '😡', '😠', '🤬', '😈', '👿', '💀',
                '☠️', '💩', '🤡', '👹', '👺', '👻', '👽', '👾', '🤖', '😺',
                '😸', '😹', '😻', '😼', '😽', '🙀', '😿', '😾',

                '👋', '🤚', '🖐️', '✋', '🖖', '👌', '🤌', '🤏', '✌️', '🤞',
                '🤟', '🤘', '🤙', '👈', '👉', '👆', '🖕', '👇', '☝️', '👍',
                '👎', '✊', '👊', '🤛', '🤜', '👏', '🙌', '👐', '🤲', '🤝',
                '🙏', '✍️', '💅', '🤳', '💪', '🦾', '🦵', '🦿', '🦶', '👂',
                '🦻', '👃', '🧠', '🫀', '🫁', '🦷', '🦴', '👀', '👁️', '👅',
                '👄', '🫦', '👶', '🧒', '👦', '👧', '🧑', '👱', '👨', '🧔',
                '🧔‍♂️', '🧔‍♀️', '👩', '🧓', '👴', '👵', '🙍‍♂️', '🙍‍♀️', '🙎‍♂️', '🙎‍♀️',

                '🐶', '🐱', '🐭', '🐹', '🐰', '🦊', '🦝', '🐻', '🐼', '🦘',
                '🦡', '🐨', '🐯', '🦁', '🐮', '🐷', '🐽', '🐸', '🐵', '🙈',
                '🙉', '🙊', '🐒', '🐔', '🐧', '🐦', '🐤', '🐣', '🐥', '🦆',
                '🦅', '🦉', '🦇', '🐺', '🐗', '🐴', '🦄', '🐝', '🐛', '🦋',
                '🐌', '🐞', '🐜', '🪲', '🕷️', '🕸️', '🦂', '🦟', '🦠', '🐢',
                '🐍', '🦎', '🦖', '🦕', '🐙', '🦑', '🦐', '🦞', '🦀', '🐡',

                '🍏', '🍎', '🍐', '🍊', '🍋', '🍌', '🍉', '🍇', '🍓', '🫐',
                '🍈', '🍒', '🍑', '🥭', '🍍', '🥥', '🥝', '🍅', '🍆', '🥑',
                '🥦', '🥬', '🥒', '🌶️', '🫑', '🌽', '🥕', '🫒', '🥔', '🍠',
                '🥐', '🍞', '🥖', '🫓', '🥨', '🥯', '🥞', '🧇', '🧀', '🍖',
                '🍗', '🥩', '🥓', '🍔', '🍟', '🍕', '🌭', '🥪', '🌮', '🌯',
                '🫔', '🥙', '🧆', '🥚', '🍳', '🥘', '🍲', '🫕', '🥣', '🥗',
                '🍿', '🧈', '🧂', '🥫', '🍱', '🍘', '🍙', '🍚', '🍛', '🍜',

                '⌚', '📱', '📲', '💻', '⌨️', '🖥️', '🖨️', '🖱️', '🖲️', '🕹️',
                '🗜️', '💽', '💾', '💿', '📀', '📼', '📷', '📸', '📹', '🎥',
                '📞', '☎️', '📟', '📠', '📺', '📻', '🎙️', '🎚️', '🎛️', '⏱️',
                '⏲️', '⏰', '🕰️', '⌛', '⏳', '📡', '🔋', '🔌', '💡', '🔦',

                '❤️', '💛', '💚', '💙', '💜', '🖤', '🤍', '🤎', '💔', '❣️',
                '💕', '💞', '💓', '💗', '💖', '💘', '💝', '💟', '☮️', '✝️',
                '☪️', '🕉️', '☸️', '✡️', '🔯', '🕎', '☯️', '☦️', '🛐', '⛩️',
                '☢️', '☣️', '📛', '⚜️', '🔰', '♻️', '✅', '🈯', '💹', '❗',
                '‼️', '⁉️', '❓', '❔', '❕', '〽️', '⚠️', '🚸', '🚫', '🔞',
                '📵', '🚯', '🚳', '🚷', '🚱', '🔔', '🔕', '✖️', '➕', '➖',
                '➗', '♾️', '💠', '⚪️', '⚫️', '🔴', '🔵', '🟠', '🟡', '🟢',
                '🟣', '🟤', '🟥', '🟦', '🟧', '🟨', '🟩', '🟪', '⬛', '⬜',
                '◼️', '◻️', '◾️', '◽️', '▪️', '▫️', '🔶', '🔷', '🔸', '🔹',
                '🔺', '🔻', '💠', '🔲', '🔳',
            ]


        };
    },
    watch: {
        original(newVal) {
            if (typeof newVal === "undefined" || newVal === null) {
                this.editor.innerHTML = "";
                this.previousContent = "";
            } else {
                this.editor.innerHTML = newVal;
                this.previousContent = newVal;
            }
            this.length = this.countCharacters(this.editor.innerText);
        },
    },
    mounted() {
        this.editor = this.$refs.editor;
        if (this.original) {
            console.log(this.original)
            this.editor.innerHTML = this.original;
            this.previousContent = this.original;
            this.length = this.countCharacters(this.editor.innerText);
        }
    },
    methods: {
        saveSelection() {
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                this.savedRange = selection.getRangeAt(0).cloneRange();
            }
        },
        restoreSelection() {
            const selection = window.getSelection();
            if (this.savedRange) {
                selection.removeAllRanges();
                selection.addRange(this.savedRange);
            }
        },
        formatText(command) {
            this.restoreSelection();
            document.execCommand(command, false, null);
            this.saveSelection();
            this.onInput();
        },
        handleKeyDown(event) {
            if (event.key === "Enter") {
                const selection = window.getSelection();
                const range = selection.getRangeAt(0);
                const startContainer = range.startContainer;

                if (this.isInsideList(startContainer)) {
                    this.saveSelection();
                    setTimeout(() => {
                        this.onInput();
                    }, 0);
                } else {
                    event.preventDefault();
                    this.insertNewLine();
                }
            }
        },
        insertNewLine() {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            range.deleteContents();

            const br = document.createElement("br");
            range.insertNode(br);
            range.collapse(false);

            selection.removeAllRanges();
            selection.addRange(range);
        },
        isInsideList(node) {
            while (node && node !== this.editor) {
                if (node.nodeName === "LI") {
                    return true;
                }
                node = node.parentNode;
            }
            return false;
        },
        onInput() {
            const text = this.editor.innerText;
            if (text.length > this.maxLength) {
                this.restorePreviousContent();
            } else {
                this.cleanContent();
                this.previousContent = this.editor.innerHTML;
                this.errorMessage = "";
                this.length = text.length;
                this.$emit("updateValue", {
                    value: this.editor.innerHTML,
                    type: this.type,
                    index: this.index,
                });
            }
        },
        restorePreviousContent() {
            this.editor.innerHTML = this.previousContent;
            this.restoreSelection();
        },
        cleanContent() {
            this.removeStyles(this.editor);
        },
        removeStyles(node) {
            if (node.nodeType === 1) {
                node.removeAttribute("style");
                for (let i = 0; i < node.childNodes.length; i++) {
                    this.removeStyles(node.childNodes[i]);
                }
            }
        },
        countCharacters(htmlContent) {
            return htmlContent.length;
        },
        insertEmoji() {
            this.showEmojiPicker = !this.showEmojiPicker;
        },
        addEmoji(emoji) {
            this.restoreSelection();
            document.execCommand("insertText", false, emoji);
            this.saveSelection();
            this.onInput();
        },
    },
};
</script>

<style>
.block_text_editor {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    max-width: 440px;
    position: relative;
}

.block_text_editor_header {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
}

.block_text_editor_header button {
    display: flex;
    border: none;
    background: #6c91bf;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    width: 25px;
    height: 20px;
    align-items: center;
    justify-content: center;
    transition: all .3s;
}

.block_text_editor_header button:hover {
    opacity: .5;
}

.block_text_editor_content {
    border: none;
    resize: none;
    box-shadow: inset 2px 2px 10px rgb(0 0 0 / 15%);
    padding: 7.5px 16px;
    font-size: 12px;
    line-height: 15px;
    color: var(--color1);
    box-sizing: border-box;
    border-radius: 10px;
    outline: none;
    min-height: 70px;
    width: 100%;
    max-height: 200px;
    overflow: auto;
}

.char-count {
    font-size: 9px;
    color: gray;
}

.block_text_editor ul,
.block_text_editor ol {
    list-style: auto;
    list-style-image: initial;
}

.block_text_editor li {
    list-style: auto;
}

.emoji-picker {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    background: #fafafa;
    box-shadow: 0px 0px 20px -9px #00000087;
    border-radius: 10px;
    padding: 10px;
    overflow: auto;
    max-height: 105px;
}

.emoji-picker button {
    padding: 5px;
    border: none;
    background: none;
    cursor: pointer;
    transition: all .3s
}

.emoji-picker button:hover {
    opacity: .5;
}
</style>