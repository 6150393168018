import { createApp } from 'vue';
import App from '@/App';
//import components from '@/components/UI'

const app = createApp(App)

// Object.keys(components).forEach((component) => {
//   app.component(components[component].name, components[component])
// })

app.mount('#bsLoader');
