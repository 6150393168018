<template>
    <div class="cropper">
        <div class="cropperContainer">

            <cropper v-show="!startCrop" class="cropperContainerCropper" ref="cropper" :src="img" :stencil-props="{
                aspectRatio: 12 / 12
            }" :default-size="defaultSize" @change="onChange" :debounce="false" background-class="cropper-background" />

            <preview v-show="!result.image.loading && !startCrop" class="cropperContainerPreview" :width="150"
                :height="150" :image="result.image.value" :coordinates="result.image.coordinates" />

        </div>

        <div v-if="!result.image.loading" class="cropperButtons">
            <button class="button button-primary" @click="crop()">Сохранить</button>
            <button class="button" @click="close()">Отмена</button>
        </div>
    </div>
</template>

<script>
import { Cropper, CircleStencil, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.classic.css';
export default {
    props: ['type', 'img'],
    emits: ["cropImg"],
    components: {
        Cropper, CircleStencil, Preview
    },
    data() {
        return {
            startCrop: false,
            result: {
                coordinates: null,
                image: {
                    value: null,
                    loading: true,
                }
            },
        }
    },
    watch: {
        'result.image.value'(value) {
            if (value) {
                this.result.image.loading = false;
            } else {
                this.result.image.loading = true;
            }
        }
    },
    methods: {
        defaultSize({ imageSize, visibleArea }) {
            return {
                width: (visibleArea || imageSize).width,
                height: (visibleArea || imageSize).height,
            };
        },
        close() {
            this.$emit("cropImg", { type: this.type, img: null });
        },
        onChange({ coordinates, image }) {
            this.result.image.value = image;
            this.result.image.coordinates = coordinates;
        },
        crop() {
            this.result.image.loading = true;
            this.startCrop = true;
            setTimeout(() => {
                const { canvas } = this.$refs.cropper.getResult();
                const imageURL = canvas.toDataURL();

                canvas.toBlob(blob => {
                    if (blob) {
                        const reader = new FileReader();
                        reader.onload = () => {
                            this.$emit("cropImg", { type: this.type, imageURL: imageURL, imageBlob: Array.from(new Uint8Array(reader.result)), blob: blob });
                        };
                        reader.readAsArrayBuffer(blob); // Чтение Blob как ArrayBuffer
                    }
                }, 'image/png');




            }, "300");
        },
    },
    computed: {
    },
}
</script>

<style>
.cropper {
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 10;
    position: fixed;
    flex-direction: column;
    background-color: #000000ad;
    backdrop-filter: blur(5px);
}

.cropperContainer {
    display: flex;
    padding: 10px;
    width: 100%;
    justify-content: center;
}

.cropperButtons {
    display: flex;
    width: 100%;
    justify-content: center;
}

.cropperButtons button {
    margin: 0px 10px;
    max-width: 300px;
    margin-top: 50px;
}

.cropperContainerCropper {
    width: 70vw;
    margin-right: 50px;
    max-width: 600px;
    max-height: 50vh;
}

.cropperContainerPreview {
    overflow: hidden;
    min-width: 150px;
    min-height: 150px;
    border: 1px solid white;
}

@media (max-width: 750px) {
    .cropperContainer {
        justify-content: center;
        flex-direction: column-reverse;
        align-items: center;
    }

    .cropperContainerCropper {
        margin: 0;
        width: 95vw;
    }

    .cropperContainerPreview {
        margin-bottom: 20px;
    }
}

.cropper-background {
    background: #00000047 !important;
}
</style>