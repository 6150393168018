<template>

  <div class="events" v-show="events.length">
    <div class="eventsElem" v-for="el in events">
      {{ el.message }}
    </div>
  </div>

  <div class="lost-error" v-show="!this.websocket || (this.websocket && !this.websocket.connectionStatus == 2)">
    <div class="lost-error-wrapper">

      <h2 class="lost-error-title" v-if="this.websocket && !this.websocket.connectionStatus == 2">Соединение потеряно
      </h2>
      <h2 class="lost-error-title" v-else>Идёт соединение с сервером</h2>

      <a class="lost-error-reload button" href="#" onclick="location.reload(); return false;">
        Перезагрузить страницу
      </a>
    </div>
  </div>

  <div>

  </div>

  <div v-if="!user" class="modal">
    <div class="modalContent">

      <div class="modalContentLoading" v-if="loadingEntry">
        Loading...
      </div>

      <form>
        <label class="modalContentElem">
          Логин
          <input type="text" v-model="login" />
        </label>

        <label class="modalContentElem">
          Пароль
          <input type="password" v-model="password" />
        </label>

        <button @click.prevent="entry" :class="{ 'disabledBtn': !password || !login }">
          Вход
        </button>
      </form>

      <div v-if="entryError" class="modalContentError">
        {{ entryError }}
      </div>

    </div>
  </div>

  <CropperImg @cropImg="cropImg" v-if="imgForCropper" :img="imgForCropper" :type="`logo`" />

  <CropperImg @cropImg="cropImgAvatar" v-if="avatarForCropper" :img="avatarForCropper" :type="`avatar`" />

  <div class="modalCreateUser" v-if="modalCreateUser || modalEditUser">
    <div class="modalCreateUserContent">

      <svg @click="modalCreateUser = false, modalEditUser = null" class="closeModal" viewBox="0 0 25 25" version="1.1"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <title>cross</title>
          <desc></desc>
          <defs> </defs>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
            <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-469.000000, -1041.000000)"
              fill="#000000">
              <path
                d="M487.148,1053.48 L492.813,1047.82 C494.376,1046.26 494.376,1043.72 492.813,1042.16 C491.248,1040.59 488.712,1040.59 487.148,1042.16 L481.484,1047.82 L475.82,1042.16 C474.257,1040.59 471.721,1040.59 470.156,1042.16 C468.593,1043.72 468.593,1046.26 470.156,1047.82 L475.82,1053.48 L470.156,1059.15 C468.593,1060.71 468.593,1063.25 470.156,1064.81 C471.721,1066.38 474.257,1066.38 475.82,1064.81 L481.484,1059.15 L487.148,1064.81 C488.712,1066.38 491.248,1066.38 492.813,1064.81 C494.376,1063.25 494.376,1060.71 492.813,1059.15 L487.148,1053.48"
                id="cross" sketch:type="MSShapeGroup"> </path>
            </g>
          </g>
        </g>
      </svg>

      <h3 v-if="modalCreateUser">Добавление аккаунта</h3>
      <h3 v-else-if="modalEditUser">Редактирование аккаунта</h3>

      <label class="createProfileElem" v-if="!loadCreate">
        Логин
        <input v-if="modalEditUser" type="text" v-model="modalEditUser.user_login">
        <input v-else type="text" v-model="newUser.login">
      </label>

      <!-- <label class="createProfileElem d-none" v-if="!loadCreate">
        Пароль
        <input v-if="modalEditUser" type="text" v-model="modalEditUser.new_password">
        <input v-else type="text" v-model="newUser.password">
      </label>

      <label class="createProfileElem d-none" v-if="!loadCreate">
        Email
        <input v-if="modalEditUser" type="text" v-model="modalEditUser.user_mail">
        <input v-else type="text" v-model="newUser.email">
      </label> -->

      <label class="createProfileElem" v-if="!loadCreate">
        ФИО
        <input v-if="modalEditUser" type="text" v-model="modalEditUser.fio">
        <input v-else type="text" v-model="newUser.fio">
      </label>

      <div class="createProfileElem" v-if="!loadCreate">
        Софт скиллы
        <div class="softSkills">
          <div v-for="elem in softSkills" class="softSkillsElem">
            <span>{{ getTr(elem.skill_name) }}</span>

            <input v-if="modalEditUser" type="number"
              v-model="getUserSoftSkillValue(modalEditUser.soft_skills, elem.skill_id).value"
              @change="checkMinValue($event)" min="0" max="100" />
            <input v-else type="number" v-model="elem.value" @change="checkMinValue($event)" min="0" max="100" />

          </div>
        </div>
      </div>

      <div class="createProfileElem" v-if="!loadCreate">
        Пол
        <div>
          <label>
            Муж
            <input v-if="modalEditUser" type="radio" value="man" name="sex" v-model="modalEditUser.user_profile_sex">
            <input v-else type="radio" value="man" name="sex" v-model="newUser.sex">
          </label>
          <label>
            Жен
            <input v-if="modalEditUser" type="radio" value="woman" name="sex" v-model="modalEditUser.user_profile_sex">
            <input v-else type="radio" value="woman" name="sex" v-model="newUser.sex">
          </label>
        </div>
      </div>

      <div class="createProfileElem" v-if="!loadCreate">
        Описание профиля
        <!-- <input v-if="modalEditUser" type="text" v-model="modalEditUser.user_profile_about">
        <input v-else type="text" v-model="newUser.description"> -->

        <TextEditor v-if="modalEditUser" :original="original.user_profile_about" @updateValue="updateTextEditor"
          :type="`user_profile_about`" :index="null" :maxLength="1000"></TextEditor>

        <TextEditor v-else :original="original.newUser_description" @updateValue="updateTextEditor"
          :type="`newUser_description`" :index="null" :maxLength="1000"></TextEditor>

      </div>

      <label class="createProfileElem createProfileElemPhoto" v-if="!loadCreate">
        Фото профиля
        <img v-if="photoURL" :src="`${photoURL}`" />
        <img v-else-if="modalEditUser" :src="`${modalEditUser.user_profile_foto}`" />
        <input type="file" id="filePhoto" ref="filePhoto" @change="fileUpload()">
      </label>

      <div v-if="modalEditUser">
        <h3>Добавить портфолио</h3>
        <div class="portfolioContentCreate">
          <label>
            Заголовок
            <input v-model="creatPortfolioTitle" name="title" type="text" required />
          </label>
          <label>
            Описание
            <input v-model="creatPortfolioDescription" type="text" name="description" />
          </label>
          <input @change="uploadPortfolioFile" type="file" name="file" required accept=".jpg,.jpeg,.png" />

          <div class="errorCreateUser" v-if="errorUploadPortfolio">{{ errorUploadPortfolio }}</div>

          <button class="button button-primary" @click="addPortfolio()">Загрузить</button>
        </div>
      </div>

      <div class="userParams">

        <label v-if="modalEditUser">
          Рейтинг (от 0 до 5.00)
          <input type="number" v-model="modalEditUser.control_rating" />
        </label>
        <label v-else>
          Рейтинг (от 0 до 5.00)
          <input type="number" v-model="newUser.rating" />
        </label>

        <label v-if="modalEditUser">
          Задач выполнено (кол-во)
          <input type="number" v-model="modalEditUser.control_tasks_closed" />
        </label>
        <label v-else>
          Задач выполнено (кол-во)
          <input type="number" v-model="newUser.tasks_closed" />
        </label>

        <label v-if="modalEditUser">
          Успешных заказов (в %)
          <input type="number" v-model="modalEditUser.control_tasks_success" />
        </label>
        <label v-else>
          Успешных заказов (в %)
          <input type="number" v-model="newUser.success_tasks" />
        </label>

        <label v-if="modalEditUser">
          Cдано вовремя (в %)
          <input type="number" v-model="modalEditUser.control_to_time" />
        </label>
        <label v-else>
          Cдано вовремя (в %)
          <input type="number" v-model="newUser.to_time" />
        </label>

      </div>

      <div v-if="loadCreate" class="loadCreate">
        Загрузка
      </div>

      <div class="errorCreateUser">{{ errorCreateUser }}</div>

      <button v-if="modalEditUser && !loadCreate" :class="{ 'disabledBtn': blockSaveUser }"
        @click="saveUser()">Сохранить</button>
      <button v-else-if="!loadCreate" :class="{ 'disabledBtn': blockCreateUser }" @click="createUser()">Создать</button>

    </div>
  </div>



  <div v-if="showContent">
    <div class="create" v-show="!loading && user">

      <transition name="fade">
        <div class="accessModalBackground" v-if="modalClear">
          <div class="accessModal">
            <div class="accessHeader"><span>{{ getTr('clear_all_fields') }}?</span></div>
            <div class="accessButtons">
              <button class="button accessClose" @click="modalClear = false">
                {{ getTr('plugins_paid_paid_operation_new_login_user_cancel') }}</button>

              <button v-if="tab == 'service'" class="button button-primary accessSave" @click="clearAllService()">{{
                getTr('сlear') }}</button>

            </div>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div class="accessModalBackground" v-if="modalRemoveArchive">
          <div class="accessModal">
            <div class="accessHeader"><span>{{ getTr('remove_draft') }}?</span></div>
            <div class="accessButtons">
              <button class="button accessClose" @click="modalRemoveArchive = false">
                {{ getTr('plugins_paid_paid_operation_new_login_user_cancel') }}
              </button>

              <button v-if="tab == 'service'" class="button button-primary accessSave"
                @click="removeService(service.service_id)">
                {{ getTr('delete') }}
              </button>

            </div>
          </div>
        </div>
      </transition>

      <div class="pageTitle" v-if="user">

        <div class="pageTitleAuth">
          <h3>Вы авторизованы: <img :src="`${user.user_profile_foto}`" /> {{ user.user_login }} </h3>
          <button @click="exit()">Выход</button>
        </div>
        <div class="changePassword">
          <label>
            Сменить пароль
            <input placeholder="Новый пароль" type="text" v-model="newPassword" />
          </label>
          <button @click="changePassword()">Сохранить</button>
        </div>

        <h4>Выберите пользователя, от которого создаём услугу</h4>
        <div class="selectUsers">
          <div class="selectUserElem btnCreateUser" @click="modalCreateUser = true">
            Создать пользователя
          </div>

          <div v-if="selectedUserID" class="button selectUserElem " @click="selectUserEdit(selectedUserID)">
            Редактировать пользователя
          </div>

          <h2>Фильтр пользователей по категориям</h2>
          <div class="selectCategory">
            <div class="selectCategoryTitle">

              <div @click="filteredCategoryIdForUsers = null" class="selectCategorySubs"
                :class="{ 'selectedSub': !filteredCategoryIdForUsers }">
                <div>Все</div>
              </div>

            </div>

            <div v-for="elem in this.categories" class="selectCategoryTitle">

              <div class="selectCategoryName">{{ elem.name }}</div>

              <div v-for="category in elem.subs" @click="filteredCategoryIdForUsers = category.category_id"
                class="selectCategorySubs"
                :class="{ 'selectedSub': filteredCategoryIdForUsers == category.category_id }">
                <div>{{ category.category_title }}</div>
                <div> (пользователей: {{ getCountUsersInCategory(category.category_id) }} ) </div>
              </div>

            </div>

          </div>

          <h3>Показано пользователей: {{ showAllUsersCount }}</h3>

          <div class="selectUsersElems">
            <div v-for="elem in filterUsersInCategory" class="selectUserElem"
              :class="{ 'selectedUser': selectedUserID == elem.user_id }" @click="selectedUserID = elem.user_id">

              <img v-if="elem.user_profile_foto" :src="`${elem.user_profile_foto}`" />

              <div class="userMainInfo">
                <div>{{ elem.user_login }}</div>
                <div>Услуг: {{ getUsersServices(elem.user_id) }}</div>
              </div>

            </div>
          </div>

          <div class="showAllUsers" v-if="showAllUsersCount == 20 && !showAllUsers" @click="showAllUsers = true">
            Показать всех</div>
          <div class="showAllUsers" v-if="showAllUsersCount >= 20 && showAllUsers" @click="showAllUsers = false">Скрыть
          </div>

        </div>


      </div>

      <div class="archive" v-if="selectedUserID">

        <h2 class="createHeader">Сервисы пользователя</h2>

        <div class="headerTabs">
          <div @click="activeTab = 'create'" :class="{ 'headerTabsElemActive': activeTab == 'create' }"
            class="headerTabsElem">
            Создание</div>
          <div @click="activeTab = 'preview'" :class="{ 'headerTabsElemActive': activeTab == 'preview' }"
            class="headerTabsElem">Превью</div>
        </div>


        <div class="createContentCategory" v-if="activeTab == 'preview'">
          <label for="category_id_service" class="category_id_service">
            <h2 class="createHeader">{{ getTr('select_a_category') }}</h2>

            <input type="text" class="profile-input input-text input-width-full" placeholder="Поиск по категории"
              v-model="searchCategory" />
          </label>

          <div class="selectCategory">
            <div class="selectCategoryTitle">

              <div @click="filterUserCategory = null" class="selectCategorySubs"
                :class="{ 'selectedSub': !filterUserCategory }">
                <div>Все</div>
              </div>

            </div>

            <div v-for="elem in filterCategory" class="selectCategoryTitle">

              <div class="selectCategoryName">{{ elem.name }}</div>

              <div v-for="category in filterCategorySubs(elem.subs)" @click="filterUserCategory = category.category_id"
                class="selectCategorySubs" :class="{ 'selectedSub': filterUserCategory == category.category_id }">
                <div>{{ category.category_title }}</div>
                <div> (всего: {{ getCountCategory(category.category_id, selectedUserID) }} ) </div>
              </div>

            </div>

          </div>

        </div>


        <div v-if="activeTab == 'create'" class="archiveContent">


          <div class="archiveContentElem" :class="{ 'selectedTask': !service.service_id }" @click="clearAllService()">
            Создать
          </div>


          <div class="archiveContentElem" v-for="elem in userServices" @click="selectedUserService = elem.service_id"
            :class="{ 'selectedTask': elem.service_id == service.service_id }">

            <h3 class="archiveContentElemName" v-if="elem.service_name">{{ elem.service_name }}</h3>
            <h3 class="archiveContentElemName" v-else>{{ getTr('name_is_not_specified') }}</h3>

            <div class="archiveContentElemPrice">{{ elem.price }} USD</div>
            <div class="archiveContentElemPrice">Доп. услуг: {{ elem.options.length }} </div>

            <div class="archiveContentElemDate">Срок выполнения: {{ elem.days_to_end }}</div>
            <div class="archiveContentElemDate">Сохранено: {{ getFormatDate(elem.date_add) }}</div>
          </div>

        </div>
      </div>


      <div v-if="activeTab == 'create'" class="createContent" v-show="tab == 'service' && selectedUserID">

        <div class="createContentCategory">
          <label for="category_id_service" class="category_id_service">
            <h2 class="createHeader">{{ getTr('select_a_category') }}</h2>

            <input type="text" class="profile-input input-text input-width-full" placeholder="Поиск по категории"
              v-model="searchCategory" />
          </label>

          <div class="selectCategory">

            <div v-for="elem in filterCategory" class="selectCategoryTitle">

              <div class="selectCategoryName">{{ elem.name }}</div>

              <div v-for="category in filterCategorySubs(elem.subs)" @click="service.category_id = category.category_id"
                class="selectCategorySubs" :class="{ 'selectedSub': service.category_id == category.category_id }">
                <div>{{ category.category_title }}</div>
                <div> (всего: {{ getCountCategory(category.category_id) }} ) </div>
              </div>

            </div>

          </div>

        </div>

        <h2 class="createHeader">{{ getTr('service_information') }}</h2>

        <div class="task-add-form-description">{{ getTr('service_name') }} <span v-if="service.service_name"
            class="input_length">{{ service.service_name.length }}/150</span></div>

        <div class="row form-item element_captions">

          <input type="text" id="task_form_name" class="profile-input input-text input-width-full" value=""
            maxlength="150" v-model="service.service_name">
          <svg @click="show_captions.service_name = !show_captions.service_name"
            :class="{ 'transformArrow': show_captions.service_name }" class="element_caption_arrow"
            viewBox="0 -4.5 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <defs> </defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Dribbble-Light-Preview" transform="translate(-220.000000, -6684.000000)" fill="#6C91BF">
                  <g id="icons" transform="translate(56.000000, 160.000000)">
                    <path
                      d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                      id="arrow_down-[#338]"> </path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="createError" v-if="!service.service_name">{{ getTr('name_is_not_specified') }}</div>
        <div class="element_captions_list" v-if="show_captions.service_name">
          <div class="element_captions_list_el" v-for="el in captions_services">
            <label>
              <span>{{ el }} <span v-if="service.captions_name[el]" class="input_length">{{
                service.captions_name[el].length }}/150</span></span>
              <input type="text" maxlength="150" v-model="service.captions_name[el]" />
            </label>
          </div>
        </div>


        <div class="task-add-form-description">{{ getTr('what_is_included_in_the_service') }}</div>

        <div class="row form-item element_captions">

          <TextEditor :original="original.service_included" @updateValue="updateTextEditor" :type="`service_included`"
            :index="null" :maxLength="1500"></TextEditor>

          <svg @click="show_captions.service_included = !show_captions.service_included"
            :class="{ 'transformArrow': show_captions.service_included }" class="element_caption_arrow"
            viewBox="0 -4.5 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <defs> </defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Dribbble-Light-Preview" transform="translate(-220.000000, -6684.000000)" fill="#6C91BF">
                  <g id="icons" transform="translate(56.000000, 160.000000)">
                    <path
                      d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                      id="arrow_down-[#338]"> </path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>

        <div class="element_captions_list" v-if="show_captions.service_included && original.service_included_captions">
          <div class="element_captions_list_el" v-for="el in captions_services">
            <span>{{ el }}</span>
            <TextEditor :original="original.service_included_captions[el]" @updateValue="updateTextEditor"
              :type="`service_included_captions`" :index="el" :maxLength="1500">
            </TextEditor>
          </div>
        </div>

        <div class="task-add-form-description">{{ getTr('it_is_necessary_for_the_order_from_the_customer') }}</div>

        <div class="row form-item element_captions">

          <TextEditor :original="original.necessary" @updateValue="updateTextEditor" :type="`necessary`" :index="null"
            :maxLength="1500"></TextEditor>

          <svg @click="show_captions.necessary = !show_captions.necessary"
            :class="{ 'transformArrow': show_captions.necessary }" class="element_caption_arrow" viewBox="0 -4.5 20 20"
            version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <defs> </defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Dribbble-Light-Preview" transform="translate(-220.000000, -6684.000000)" fill="#6C91BF">
                  <g id="icons" transform="translate(56.000000, 160.000000)">
                    <path
                      d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                      id="arrow_down-[#338]"> </path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>

        <div class="element_captions_list" v-if="show_captions.necessary && original.necessary_captions">
          <div class="element_captions_list_el" v-for="el in captions_services">
            <span>{{ el }}</span>
            <TextEditor :original="original.necessary_captions[el]" @updateValue="updateTextEditor"
              :type="`necessary_captions`" :index="el" :maxLength="1500">
            </TextEditor>
          </div>
        </div>

        <div class="task-add-form-description">{{ getTr('additional_information') }}</div>

        <div class="row form-item element_captions">

          <TextEditor :original="original.additional_information" @updateValue="updateTextEditor"
            :type="`additional_information`" :index="null" :maxLength="1500"></TextEditor>

          <svg @click="show_captions.additional_information = !show_captions.additional_information"
            :class="{ 'transformArrow': show_captions.additional_information }" class="element_caption_arrow"
            viewBox="0 -4.5 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <defs> </defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Dribbble-Light-Preview" transform="translate(-220.000000, -6684.000000)" fill="#6C91BF">
                  <g id="icons" transform="translate(56.000000, 160.000000)">
                    <path
                      d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                      id="arrow_down-[#338]"> </path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        
        <div class="element_captions_list"
          v-if="show_captions.additional_information && original.additional_information_captions">
          <div class="element_captions_list_el" v-for="el in captions_services">
            <span>{{ el }}</span>
            <TextEditor :original="original.additional_information_captions[el]" @updateValue="updateTextEditor"
              :type="`additional_information_captions`" :index="el" :maxLength="1500">
            </TextEditor>
          </div>
        </div>




        <label class="files_elems" for="avatarForCropper">
          Превью услуги
        </label>

        <div class="createError" v-if="!avatarURL && !service.service_logo">Не загружено превью</div>

        <div v-if="avatarURL || service.service_logo" class="serviceAvatar">
          <img v-if="avatarURL" :src="`${avatarURL}`" />
          <img v-else :src="`${url}/uploads/services/logo/${service.service_logo}`" />
        </div>

        <input type="file" id="avatarForCropper" name="files" accept=".jpeg, .jpg, .png" @change="avatarUpload"
          ref="avatarPhoto" multiple style="visibility: hidden;width: 1px;height: 1px;margin: -1px;">
        <button class="files_elems-button button" onclick="document.getElementById('avatarForCropper').click()">
          Загрузить превью
        </button>

        <label class="files_elems" for="service_files_public">
          {{ getTr('plugins_usertask_task_create_files') }}
        </label>
        <input type="file" id="service_files_public" name="files" accept=".jpeg, .jpg, .png"
          @change="handleFileInputService" multiple style="visibility: hidden;width: 1px;height: 1px;margin: -1px;">
        <button class="files_elems-button button" onclick="document.getElementById('service_files_public').click()">
          {{ getTr('download_files') }}
        </button>

        <h2 v-if="service.files && service.files.length">{{ getTr('plugins_usertask_task_uploaded_files_public') }}
        </h2>

        <div class="taskFiles" v-if="service.files && service.files.length">

          <div class="taskFilesFile" data-fileid="{$file['file_id']}" v-for="(elem, index) in service.files">
            <div v-if="!elem.fileName" class="taskFilesFile">
              <div class="taskFilesFileDelete" @click="removeFileService(index)">X</div>
              <div class="taskFilesFileImg">{{ getFileExtension(elem.name) }}</div>
              <a class="taskFilesFileName">
                {{ elem.name }}
              </a>
            </div>

            <div v-else class="taskFilesFile">
              <div class="taskFilesFileDelete" @click="removeFileService(index)">X</div>
              <div class="taskFilesFileImg">{{ elem.extension }}</div>
              <a class="taskFilesFileName" :href="`/uploads/services/files/${elem.file}.${elem.extension}`"
                :download="elem.fileName">
                {{ elem.fileName }}
              </a>
            </div>
          </div>

        </div>

        <div class="service_options">

          <h3>{{ getTr('plugins_usertask_task_additional_options') }}</h3>



          <div class="serviceOption" v-for="(elem, index) in service.options">



            <div class="task-add-form-description">{{ getTr('plugins_usertask_task_additional_option_name') }} <span
                v-if="service.options[index].option_name" class="input_length">{{
                  service.options[index].option_name.length }}/100</span></div>

            <div class="row form-item element_captions">
              <input :placeholder="getTr('plugins_usertask_task_additional_option_name')"
                class="serviceOptionContentName" type="text" maxlength="100"
                v-model="service.options[index].option_name" />
              <svg @click="service.options[index].show_name = !service.options[index].show_name"
                :class="{ 'transformArrow': service.options[index].show_name }" class="element_caption_arrow"
                viewBox="0 -4.5 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <defs> </defs>
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Dribbble-Light-Preview" transform="translate(-220.000000, -6684.000000)" fill="#6C91BF">
                      <g id="icons" transform="translate(56.000000, 160.000000)">
                        <path
                          d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                          id="arrow_down-[#338]"> </path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>

            <div class="element_captions_list" v-if="service.options[index].show_name">
              <div class="element_captions_list_el" v-for="el in captions_services">
                <label>
                  <span>{{ el }} <span v-if="service.options[index].captions_name[el]" class="input_length">{{
                    service.options[index].captions_name[el].length }}/100</span></span>
                  <input type="text" maxlength="100" v-model="service.options[index].captions_name[el]" />
                </label>
              </div>
            </div>

            <div class="serviceOptionContent">
              <input :placeholder="getTr('plugins_usertask_task_additional_option_input_price')"
                class="serviceOptionContentPrice" type="number" v-model="service.options[index].option_price" />

              <div class="serviceOptionContentPriceCourse">
                ~{{ getPriceRUB(service.options[index].option_price) }} RUB
              </div>

              <input :placeholder="getTr('plugins_usertask_days')" class="serviceOptionContentDays" type="number"
                v-model="service.options[index].option_days" />
            </div>

            <div class="task-add-form-description">{{ getTr('hint_for_the_customer') }} <span
                v-if="service.options[index].option_description" class="input_length">{{
                  service.options[index].option_description.length }}/100</span></div>

            <div class="row form-item element_captions">
              <input class="serviceOptionDescription" :placeholder="getTr('hint_for_the_customer')" type="text"
                v-model="service.options[index].option_description" maxlength="100" />
              <svg @click="service.options[index].show_description = !service.options[index].show_description"
                :class="{ 'transformArrow': service.options[index].show_description }" class="element_caption_arrow"
                viewBox="0 -4.5 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <defs> </defs>
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Dribbble-Light-Preview" transform="translate(-220.000000, -6684.000000)" fill="#6C91BF">
                      <g id="icons" transform="translate(56.000000, 160.000000)">
                        <path
                          d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                          id="arrow_down-[#338]"> </path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div class="element_captions_list" v-if="service.options[index].show_description">
              <div class="element_captions_list_el" v-for="el in captions_services">
                <label>
                  <span>{{ el }} <span v-if="service.options[index].captions_description[el]" class="input_length">{{
                    service.options[index].captions_description[el].length }}/100</span></span>
                  <input type="text" maxlength="100" v-model="service.options[index].captions_description[el]" />
                </label>
              </div>
            </div>
          </div>

          <div class="service_options_add">
            {{ getTr('add_an_option') }}
            <button class="button" @click="addOption()">+</button>
          </div>

        </div>

        <div class="row js-task-row-price">
          <label class="row-label">{{ getTr('days_to_complete') }}</label>
          <div class="row-price-value">
            <div class="task-price-wrapper">
              <ul class="presets js-task-set-price clearfix" id="task_form_presets">
                <li class="item my-price js-task-my-price form-item">
                  <label class="my-price-label" for="days_to_end">
                    <input type="number" class="profile-input input-text input-width-100 js-custom-field js-price-input"
                      id="days_to_end" v-model="service.days_to_end">
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="createError" v-if="!service.days_to_end">
          {{ getTr('number_of_days_for_execution_has_not_been_entered') }}</div>

        <div class="row js-task-row-price">
          <label v-if="tab == 'task'" class="row-label">{{ getTr('plugins_usertask_task_form_price') }}</label>
          <label v-else class="row-label">{{ getTr('plugins_services_service_price') }}</label>
          <div class="row-price-value">
            <div class="task-price-wrapper">
              <ul class="presets js-task-set-price clearfix" id="task_form_presets">
                <li class="item my-price js-task-my-price form-item">
                  <label class="my-price-label" for="task_form_my_price">
                    <input type="text" class="profile-input input-text input-width-100 js-custom-field js-price-input"
                      maxlength="6" id="task_form_my_price" v-model="service.price"
                      :placeholder="getTr('plugins_usertask_task_form_price_other')">
                    <span>USD</span>
                    <div class="serviceOptionContentPriceCourse">
                      ~{{ getPriceRUB(service.price) }} RUB
                    </div>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="createError" v-if="!service.price">{{ getTr('cost_has_not_been_entered') }}</div>

        <h3 class="servicePortfolioTitle">{{ getTr('select_a_portfolio_for_the_service') }}</h3>

        <label class="servicePortfolio">
          {{ getTr('search_by_name_and_description') }}
          <input type="text" v-model="searchPortfolio" />

          <div class="button" @click="selectAllPortfolio()" v-if="filterPortfolio.length">
            {{ getTr('select_all') }}
          </div>

        </label>

        <div class="portfolioContent">

          <div class="portfolioContentElem" v-for="elem in filterPortfolio" @click="elem.checked = !elem.checked">
            <div class="portfolioContentElemChecked" v-if="elem.checked">
              <img :src="`${url}templates/skin/bs/images/check_mark.svg`" />
            </div>

            <img :src="`${url}uploads/portfolio/${elem.image}`">
            <div class="portfolioContentElemDescription">
              <div class="portfolioContentElemDescriptionTitle">{{ elem.title }}</div>
              <div class="portfolioContentElemDescriptionMess">{{ elem.description }}</div>
            </div>
          </div>

        </div>

        <div class="createButtons">

          <button class="button button-primary" :class="{ 'disabledBtn': blockSaved }" @click="saveService(true)">
            {{ getTr('publish') }}
          </button>
          <!-- <button class="button" @click="modalClear = true">
              {{ getTr('сlear') }}
            </button> -->
          <button v-if="tab == 'service' && selectedUserService" class="button button-primary accessSave"
            @click="removeService(selectedUserService)">
            {{ getTr('delete') }}
          </button>
        </div>

      </div>


      <div v-if="previewServicePage" class="previewServicePage">

        <div @click="previewServicePage = null" class="previewServicePageBack">Назад</div>

        <div class="serviceContainer">

          <div class="profile-card serviceContainerBLock">
            <div class="profile-info">
              <div class="profile-info-block">

                <div class="user-online"></div>

                <div class="profile-avatar">
                  <img :src="`${getUserById(selectedUserID).user_profile_foto}`" alt="Zoomdesign">
                </div>
                <div class="profile-name">
                  <h2>{{ getUserById(selectedUserID).user_login }}</h2>
                  <p>({{ getUserById(selectedUserID).fio }})</p>
                </div>
              </div>

            </div>

            <div class="profile-stats">
              <div class="stat-item">
                <div class="stat-icon">
                  <img :src="`${url}/plugins/services/templates/skin/default/images/star.png`">
                </div>
                <div class="stat-info">
                  <div class="stat-value">{{getUserById(selectedUserID).control_rating}}</div> <span
                    class="stat-label">Рейтинг</span>
                </div>
              </div>
              <div class="stat-item">
                <div class="stat-icon">
                  <img :src="`${url}/plugins/services/templates/skin/default/images/work.png`">
                </div>
                <div class="stat-info">
                  <span class="stat-value">{{getUserById(selectedUserID).control_tasks_closed}}</span>
                  <span class="stat-label">задач выполнено</span>
                </div>
              </div>
              <div class="stat-item">
                <div class="stat-icon">
                  <img :src="`${url}/plugins/services/templates/skin/default/images/likes.png`">
                </div>
                <div class="stat-info">
                  <span class="stat-value">{{getUserById(selectedUserID).control_tasks_success}}%</span>
                  <span class="stat-label">успешных заказов</span>
                </div>
              </div>
              <div class="stat-item">
                <div class="stat-icon">
                  <img :src="`${url}/plugins/services/templates/skin/default/images/time.png`">
                </div>
                <div class="stat-info">
                  <span class="stat-value">{{getUserById(selectedUserID).control_to_time}}%</span>
                  <span class="stat-label">сдано вовремя</span>
                </div>
              </div>
            </div>

          </div>

          <div class="serviceContainerBLock">
            <div class="photoSliderHeader">
              <div class="photoSliderHeaderTitle">
                <h2 class="mainTitlePage">{{ previewServicePage.service_name }}</h2>
                <span class="photoSliderHeaderTitleBread"></span>
              </div>
              <div class="photoSliderHeaderReview d-none">
                <!-- <div class="stat-icon">
                  <img src="/plugins/services/templates/skin/default/images/like.png">
                </div>
                <div class="stat-icon">
                  <img src="/plugins/services/templates/skin/default/images/attention.png">
                </div>
                <div class="stat-icon">
                  <img src="/plugins/services/templates/skin/default/images/hide.png">
                </div> -->
              </div>
            </div>

            <div class="photoSliderContent">
              <div class="photoSliderContentMain">
                <img id="mainPhoto" :src="`${url}/uploads/services/logo/${previewServicePage.service_logo}`">
              </div>
              <div class="photoSliderContentAdditional">

                <div class="additional-photo additional-photo-active">
                  <img :src="`${url}uploads/services/logo/${previewServicePage.service_logo}`">
                </div>

                <div class="additional-photo" v-for="elem in previewServicePage.files">
                  <img :src="`${url}uploads/services/files/${elem.file}.${elem.extension}`">
                </div>

              </div>
            </div>
          </div>

          <div class="serviceContainerBLock">
            <h2 class="mainTitlePage">Дополнительные услуги</h2>

            <div class="additionalOptions">

              <div class="optionElem" v-for="elem in previewServicePage.options">
                <div class="optionElemDescription">

                  <div class="optionElemHeader">
                    <div>{{ elem.option_name }}</div>
                    <div class="optionElemHeaderDescription">{{ elem.option_description }}</div>
                  </div>
                </div>

                <div class="optionParams">
                  <div class="optionElemHeaderDays">
                    <span id="optionDays">{{ elem.option_days }} дн</span>
                  </div>
                  <div class="optionElemHeaderPrice">
                    <span id="optionPrice">{{ elem.option_price }}</span>
                    <img :src="`${url}/templates/skin/bs/images/coin.ico`" class="platformCurrency">
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="serviceContainerBLock serviceContainerBLockWithoutBack">
            <h2 class="mainTitlePage">Об услуге</h2>
            <div class="serviceDescription">




              <div class="serviceDescriptionBlock" v-if="previewServicePage.service_included">
                <h3>{{ getTr('what_is_included_in_the_service') }}</h3>
                <div class="serviceDescriptionBlockContent" v-html="previewServicePage.service_included">
                </div>
              </div>


              <div class="serviceDescriptionBlock" v-if="previewServicePage.necessary">
                <h3>{{ getTr('it_is_necessary_for_the_order_from_the_customer') }}</h3>
                <div class="serviceDescriptionBlockContent" v-html="previewServicePage.necessary">
                </div>
              </div>



              <div class="serviceDescriptionBlock" v-if="previewServicePage.additional_information">
                <h3>{{ getTr('additional_information') }}</h3>
                <div class="serviceDescriptionBlockContent" v-html="previewServicePage.additional_information">
                </div>
              </div>


            </div>
          </div>

          <div class="serviceContainerBLock" id="containerPortfolio">
            <h2 class="mainTitlePage">Портфолио</h2>
            <div class="portfolioContent" v-if="previewServicePage.portfolio.length">

              <div class="portfolioContentElem" v-for="elem in previewServicePage.portfolio">

                <img v-if="getPortfolioById(elem)" :src="`${url}/uploads/portfolio/${getPortfolioById(elem).image}`">
                <div class="portfolioContentElemDescription" v-if="getPortfolioById(elem)">
                  <div class="portfolioContentElemDescriptionTitle">{{ getPortfolioById(elem).title }}</div>
                  <div class="portfolioContentElemDescriptionMess">{{ getPortfolioById(elem).description }}</div>
                </div>

              </div>

            </div>

            <div class="contentNotFound" v-else>
              Портфолио нет
            </div>

          </div>

        </div>

      </div>

      <div v-if="activeTab == 'preview' && !previewServicePage" class="previewContent view_blocks">
        <li class="task_item_wrapper" v-for="elem in filterUserServices" @click="previewServicePage = elem">

          <div class="service_logo">
            <img v-if="elem.service_logo" :src="`${url}/uploads/services/logo/${elem.service_logo}`" />
            <img v-else :src="`${url}/uploads/services/logo/no_logo.png`" />
          </div>

          <div class="task_item_header clearfix">
            <div class="task_item_title_wrapper">
              <span class="task_item_title">{{ elem.service_name }}</span>
            </div>
            <div class="task_item_price_wrapper task_block_price">
              <span class="task_item_price task_price">
                <span class="val">{{ elem.price }}</span>
                <span class="sign ruble-arial">USD</span>
              </span>
            </div>
          </div>
          <div class="task_item_user">
            <div class="user-avatar">
              <img :src="`${getUserById(selectedUserID).user_profile_foto}`" alt="">
            </div>
            <div class="user-info">
              <div class="user-name">{{
                getUserById(selectedUserID).user_login }}</div>
              <p class="user-online">
                Онлайн
              </p>
            </div>
          </div>

        </li>
      </div>


      <div class="createError" v-if="errorAPI">{{ errorAPI }}</div>
    </div>

  </div>


  <transition name="fade">
    <div class="loadingCreate" v-if="loading">
      <img src="/templates/skin/bs/images/logo.svg" />
      Загрузка
    </div>
  </transition>
</template>
<script>

import axios from 'axios';
import CropperImg from "@/CropperImg";
import TextEditor from './TextEditor.vue';
export default {
  components: {
    CropperImg,
    TextEditor
  },
  data() {
    return {

      errorUploadPortfolio: null,

      percentageClosed: 'Loading',
      percentTaskOnTime: 'Loading',
      countClosed: 'Loading',
      userRating: 'Loading',

      showAllUsersCount: 0,
      showAllUsers: false,

      modalEditUser: null,
      previewServicePage: null,

      errorCreateUser: null,

      activeTab: 'create',

      filterUserCategory: null,

      captions_task: [],
      captions_services: [],

      show_captions: {
        task_name: false,
        task_description: false,
        task_description_private: false,
      },

      creatPortfolioTitle: "",
      creatPortfolioDescription: "",
      creatPortfolioFile: null,

      modalCreateUser: false,
      loadCreate: false,

      filteredCategoryIdForUsers: null,

      websocket: null,
      intervalReconnection: null,

      selectedUserID: null,

      searchCategory: null,

      user: null,

      caption: "caption_russian",
      url: window.location.href == "https://localhost:8089/" ? "https://brainshaker.ok/" : "https://bshaker.ru/",

      errorAPI: null,

      createTaskFromService: null,

      modalRemoveArchive: false,
      modalClear: false,

      showError: false,

      search_skill: '',

      showContent: false,
      loading: false,
      tab: 'service',

      searchPortfolio: "",
      userPortfolio: [],

      projects: [],
      levels: [],
      categories: [],
      skills: [],

      archiveTasks: [],
      archiveServices: [],

      service: {
        service_id: 0,
        service_name: '',
        category_id: 1,
        days_to_end: 0,
        price: 0,
        additional_information: '',
        service_included: '',
        necessary: '',
        files: [],
        options: [],
        captions_name: {},
        additional_information_captions: {},
        service_included_captions: {},
        necessary_captions: {},
      },

      avatarBlob: null,
      avatarURL: null,

      avatarForCropper: null,

      task: {
        minutes: null,
        hours: null,

        files: [],
        files_private: [],
        skills: [],
        options: [],

        task_id: 0,
        user_id: 0,
        performer_id: 0,
        category_id: 1,

        task_price_def: 5,

        task_date_to: 0,

        task_name: '',
        task_description: '',
        task_private: '',

        task_openprice: 0,
        task_price: null,
        task_status: 0,
        archive: 0,
        is_service: 0,
        level: 1,
        project_id: 0,
        issue_id: 0,
        sticker_id: 0,

        add_private_info: false,

        captions_name: {},
        captions_description: {},
        captions_description_private: {},
      },
      selectedArchive: null,

      login: "",
      password: "",
      entryError: null,

      imgForCropper: null,

      newUser: {
        login: null,
        password: null,
        email: null,
        description: null,
        sex: 'man',
        photoBlob: null,
        fio: null,
      },

      userServices: [],
      selectedUserService: null,

      photoURL: null,

      comission: 0,
      course_usd: 0,

      events: [],
      allServices: [],

      translations: [],
      loadingEntry: false,

      newPassword: null,
      softSkills: [],

      original: {
        additional_information: "",
        service_included: "",
        necessary: "",

        additional_information_captions: {},
        service_included_captions: {},
        necessary_captions: {},
      },

    }
  },
  async mounted() {

    // Слушаем подключение/отключение интернета
    window.addEventListener('online', this.listenerOnline);
    window.addEventListener('offline', this.listenerOffline);

    await this.getTranslations();

    await this.getComission();
    await this.getAllServices();
    await this.getSoftSkills();
    await this.connectWebSocket();

    this.checkEntry();


  },
  methods: {
    getPortfolioById(id) {
      for (let i in this.userPortfolio) {
        if (this.userPortfolio[i].portfolio_id == id) return this.userPortfolio[i];
      }

      return null;
    },
    uploadPortfolioFile(event) {
      const file = event.target.files[0]; // Получаем первый загруженный файл
      if (file) {
        this.creatPortfolioFile = file; // Сохраняем файл в переменную
      }
    },
    selectAllPortfolio() {
      this.filterPortfolio.forEach(element => {
        element.checked = true;
      });
    },
    selectUserEdit(userID) {
      this.modalEditUser = this.getUserById(userID);
      this.original.user_profile_about = this.getUserById(userID).user_profile_about;
    },
    async generatePassword() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const minLength = 6;
      const maxLength = 8;
      const passwordLength = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
      let password = '';

      for (let i = 0; i < passwordLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        password += characters[randomIndex];
      }

      return password;
    },
    updateTextEditor(obj) {

      if (obj.type == "service_included") {
        this.service.service_included = obj.value;
      } else if (obj.type == "service_included_captions") {
        this.service.service_included_captions[obj.index] = obj.value;
      }

      if (obj.type == "necessary") {
        this.service.necessary = obj.value;
      } else if (obj.type == "necessary_captions") {
        this.service.necessary_captions[obj.index] = obj.value;
      }

      if (obj.type == "additional_information") {
        this.service.additional_information = obj.value;
      } else if (obj.type == "additional_information_captions") {
        this.service.additional_information_captions[obj.index] = obj.value;
      }

      if (obj.type == "user_profile_about") {
        this.modalEditUser.user_profile_about = obj.value;
      } else if (obj.type == "newUser_description") {
        this.newUser.description = obj.value;
      }

    },
    getUserSoftSkillValue(array, skillID) {
      for (let i in array) {
        if (array[i].skill_id == skillID) {
          return array[i];
        }
      }

      return array[array.length] = {
        skill_id: skillID,
        value: 0,
      }

    },
    getUserById(userID) {
      for (let i in this.user.usersControl) {
        if (this.user.usersControl[i].user_id == userID) return this.user.usersControl[i];
      }
    },
    openURL(url) {
      window.open(url, '_blank');
    },
    checkMinValue(event) {
      if (event.target.value < 0) event.target.value = 0;
    },
    changePassword() {
      const message = {
        action: "changePassword",
        newPassword: this.newPassword,
      }
      this.websocket.send(message);
    },
    getUsersServices(userID) {
      let count = 0;
      for (let i in this.allServices) {
        if (this.allServices[i].user_id == userID) count++;
      }
      return count;
    },
    async addPortfolio() {
      this.errorUploadPortfolio = null;

      const formData = new FormData();

      if(!this.creatPortfolioTitle) return this.errorUploadPortfolio = "Не указано название портфолио";
      if(!this.creatPortfolioFile) return this.errorUploadPortfolio = "Файл не загружен";

      formData.append(`userID`, this.selectedUserID);
      formData.append(`file`, this.creatPortfolioFile);
      formData.append(`title`, this.creatPortfolioTitle);
      formData.append(`description`, this.creatPortfolioDescription);

      try {
        const response = await axios.post(
          this.url + '/create/api?event=addPortfolio',
          formData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        if (response.data.data == 'success') {
          this.loadUserPortfolio(this.selectedUserID);
          this.creatPortfolioTitle = null;
          this.creatPortfolioFile = null;
          this.creatPortfolioDescription = null;
          this.createEvent('Портфолио добавлено');
        }

      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async getSoftSkills() {
      try {
        const response = await axios.post(
          this.url + '/create/api?event=getSoftSkills',
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        this.softSkills = response.data.data;

      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async getAllServices() {
      try {
        const response = await axios.post(
          this.url + '/create/api?event=getAllServices',
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        this.allServices = response.data.data;

      } catch (e) {
        console.log(e);
        return false;
      }
    },
    getCountUsersInCategory(categoryID) {
      let count = 0;
      let arrayUsersID = [];
      for (let i in this.allServices) {
        if (this.allServices[i].category_id == categoryID) {
          const user = this.getUserById(this.allServices[i].user_id);
          if (user && !arrayUsersID.includes(user.user_id)) {
            arrayUsersID.push(user.user_id);
            count++;
          }
        }
      }
      return count;
    },
    getCountCategory(categoryID, userID = null) {
      let count = 0;
      for (let i in this.allServices) {

        if (userID) {
          if (this.allServices[i].category_id == categoryID && this.allServices[i].user_id == userID) count++;
        } else {
          if (this.allServices[i].category_id == categoryID) count++;
        }
      }
      return count;
    },
    getPriceRUB(price) {
      if (!price) return 0;
      return Math.round(Number(price) * Number(this.course_usd));
    },
    exit() {
      localStorage.entry_key = null;
      this.user = null;
      this.websocket.user = null;
    },
    checkEntry() {
      if (!localStorage.entry_key) return;

      const message = {
        action: "checkEntry",
        entry_key: localStorage.entry_key,
      }
      this.websocket.send(message);

    },
    createEvent(message) {
      const newObject = { id: Date.now(), message: message };
      this.events.push(newObject);
      setTimeout(() => {
        const index = this.events.findIndex(item => item.id === newObject.id);
        if (index !== -1) {
          this.events.splice(index, 1);
        }
      }, 5000);
    },

    clearServiceAndCreateNew() {
      this.clearAllService();
      this.selectedUserService = null;
    },
    saveUser() {
      this.modalEditUser.photoBlob = this.newUser.photoBlob;

      this.loadCreate = true;
      this.errorCreateUser = null;

      const message = {
        action: "editUser",
        editUser: this.modalEditUser,
        superUserID: this.user.user_id,
      }
      this.websocket.send(message);
    },
    async createUser() {
      this.loadCreate = true;
      this.errorCreateUser = null;

      const softSkills = [];
      for (let i in this.softSkills) {
        if (this.softSkills[i].value) {
          softSkills.push(this.softSkills[i]);
        }
      }

      this.newUser.password = await this.generatePassword();

      const message = {
        action: "createUser",
        newUser: this.newUser,
        softSkills: softSkills,
        superUserID: this.user.user_id,
      }
      this.websocket.send(message);
    },


    cropImg(obj) {
      const imageURL = obj.imageURL;
      const imageBlob = obj.imageBlob;

      if (imageURL) {
        this.errorLogo = '';
        this.photoURL = imageURL;
        this.newUser.photoBlob = imageBlob;
      }

      this.imgForCropper = null;
    },
    fileUpload() {
      this.imgForCropper = URL.createObjectURL(event.target.files[0]);
      this.$refs.filePhoto.value = "";
    },


    cropImgAvatar(obj) {
      const imageURL = obj.imageURL;
      const imageBlob = obj.imageBlob;

      if (imageURL) {
        this.errorLogo = '';
        this.avatarURL = imageURL;
        this.avatarBlob = obj.blob;
      }

      this.avatarForCropper = null;
    },
    avatarUpload() {
      this.avatarForCropper = URL.createObjectURL(event.target.files[0]);
      this.$refs.avatarPhoto.value = "";
    },

    mainFunctionAfrerEntry() {
      this.showContent = true;
      this.hideMainLoading();

      this.mainFunctionApp();

    },
    entry() {
      this.loadingEntry = true;
      this.entryError = null;
      const message = {
        action: "entry",
        login: this.login,
        password: this.password,
      }
      this.websocket.send(message);
    },

    async getComission(serviceID) {
      try {
        const response = await axios.post(
          this.url + '/create/api?event=getComission',
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        this.comission = response.data.data[0].percent_task;
        this.course_usd = response.data.data[0].course_usd;

      } catch (e) {
        console.log(e);
        return false;
      }
    },

    async getTranslations() {
      try {
        const response = await axios.post(
          this.url + '/calendar/ajax/property?getTranslations=caption_russian',
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        this.createTranslationMap(response.data);
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    updateDateTask(date, id = false) {
      this.task.task_date_to = this.formatDateForTask(date);
    },
    getCountOption(optionID) {
      if (this.getOptionFromService(optionID).count) return this.getOptionFromService(optionID).count;
      this.getOptionFromService(optionID).count = 1;
      return this.getOptionFromService(optionID).count;
    },
    async changeCountService(optionID, plus = true) {
      if (plus) {
        this.getOptionFromService(optionID).count++;
      } else {
        if (this.getOptionFromService(optionID).count < 2) {
          this.getOptionFromService(optionID).count = 1;
        } else {
          this.getOptionFromService(optionID).count--;
        }
      }
    },
    async addOption() {
      this.service.options.push({
        option_id: 0,
        service_id: 0,
        option_name: '',
        option_description: '',
        option_price: null,
        option_days: null,
        captions_name: {

        },
        captions_description: {

        },
      });
    },
    async validateData() {

      this.showError = true;

      if (this.tab == 'service') {

      }

      if (this.tab == 'task') {

        if (!this.task.minutes) return false;
        if (!this.task.hours) return false;
        if (!this.task.category_id) return false;

        if (!this.task.task_name) return false;
        if (!this.task.task_description) return false;

      }

      return true;
    },
    formatDateForTask(isoString) {
      const date = new Date(isoString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    getFormatDate(taskDate) {

      let date = new Date(taskDate);

      let day = date.getDate(); // Получаем день месяца
      let month = date.getMonth() + 1; // Получаем месяц (нумерация начинается с 0)
      let year = date.getFullYear(); // Получаем год
      let hours = date.getHours(); // Получаем часы
      let minutes = date.getMinutes(); // Получаем минуты

      // Форматируем день и месяц, чтобы они были двузначными, если нужно
      day = day < 10 ? '0' + day : day;
      month = month < 10 ? '0' + month : month;

      // Формируем строку с читаемой датой
      return `${day}.${month}.${year}, ${hours}:${minutes}`;
    },
    loadSelects2() {
      replaceSelects();
    },


    addDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    formatDateToInput(date) {
      let day = date.getDate();
      let month = date.getMonth() + 1; // Месяцы в JavaScript начинаются с 0
      let year = date.getFullYear();

      // Форматируем день и месяц, чтобы они были двузначными
      day = day < 10 ? '0' + day : day;
      month = month < 10 ? '0' + month : month;

      return `${year}-${month}-${day}`;
    },
    async loadTaskFromIssue(issue) {
      this.createTaskFromIssue = issue;

      // Подставляем дату в инпут, добавляя дни на выполнение к сегодняшней дате
      let currentDate = new Date(issue.timer);
      let formattedDate = this.formatDateToInput(currentDate);

      this.task.task_date_to = formattedDate;
      this.task.task_openprice = 0;
      this.task.task_price = issue.price;
      this.task.task_description = issue.description;
      this.task.task_name = issue.text;
      this.task.issue_id = issue.issue_id;

      this.task.skills = [];
      issue.skills.forEach(element => {
        this.task.skills.push(element.skill_id);
        this.getSkillById(element.skill_id).level = 1;
      });

      $('#task_skills').val(this.task.skills).trigger('change');

      this.showError = true;
    },
    async loadTaskFromService(service) {
      this.createTaskFromService = service;

      // Подставляем дату в инпут, добавляя дни на выполнение к сегодняшней дате
      let currentDate = new Date();
      let daysToAdd = Number(service.days_to_end); // Количество дней для добавления
      let newDate = this.addDays(currentDate, daysToAdd);
      let formattedDate = this.formatDateToInput(newDate);

      this.task.task_date_to = formattedDate;
      this.task.task_openprice = 0;
      this.task.task_price = service.price;
      this.task.category_id = service.category_id;

      this.showError = true;
    },
    async loadService(service) {

      for (let i in this.userPortfolio) {
        if (service.portfolio.includes(this.userPortfolio[i].portfolio_id)) {
          this.userPortfolio[i].checked = true;
        } else {
          this.userPortfolio[i].checked = false;
        }
      }

      this.original = {};

      this.original.additional_information_captions = {};
      this.original.service_included_captions = {};
      this.original.necessary_captions = {};

      for (let i in service.options) {
        if (!service.options[i].captions_name) service.options[i].captions_name = {};
        if (!service.options[i].captions_description) service.options[i].captions_description = {};
      }

      for (let i in service.additional_information_captions) {
        if (i != 'type' && i != 'service_id' && i != 'row_id') {
          this.original.additional_information_captions[i] = service.additional_information_captions[i];
        }
      }

      for (let i in service.service_included_captions) {
        if (i != 'type' && i != 'service_id' && i != 'row_id') {
          this.original.service_included_captions[i] = service.service_included_captions[i];
        }
      }

      for (let i in service.necessary_captions) {
        if (i != 'type' && i != 'service_id' && i != 'row_id') {
          this.original.necessary_captions[i] = service.necessary_captions[i];
        }
      }

      this.original.additional_information = service.additional_information;
      this.original.service_included = service.service_included;
      this.original.necessary = service.necessary;

      Object.assign(this.service, service);

    },
    getFileExtension(filename) {
      const match = filename.match(/\.([^.]+)$/);
      return match ? match[1] : '';
    },
    removeFile(array, index) {
      this.task[array].splice(index, 1);
    },
    removeFileService(index) {
      this.service.files.splice(index, 1);
    },

    async saveService(publish = false) {

      this.loading = true;

      this.errorAPI = null;
      const formData = new FormData();

      formData.append(`fromLoader`, true);
      formData.append(`userCreator`, this.selectedUserID);
      formData.append(`publish`, publish);

      formData.append(`avatarService`, this.avatarBlob);

      const selectedPortfolio = [];
      for (let i in this.userPortfolio) {
        if (this.userPortfolio[i].checked) {
          selectedPortfolio.push(this.userPortfolio[i].portfolio_id);
        }
      }
      this.service.selectedPortfolio = selectedPortfolio;

      this.service.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });

      formData.append(`service`, JSON.stringify(this.service));

      try {
        const response = await axios.post(
          this.url + '/create/api?event=saveService',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        this.loading = false;

        if (response.data.status == 'success') {

          if (publish) {
            const message = {
              action: "service",
              serviceID: response.data.data,
              create: this.service.service_id && !publish ? false : true,
            }
            this.websocket.send(message);



          }

          this.clearAllService();
          await this.loadUserServices();

          this.createEvent('Услуга сохранена');

          this.getAllServices();

        } else {
          this.errorAPI = response.data && response.data.data ? response.data.data : this.getTr('system_error');
        }

      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async saveTask(publish = false, serviceID = false) {

      this.errorAPI = null;

      if (!this.validateData()) return;

      this.task.selectedSkills = [];

      const formData = new FormData();

      formData.append(`publish`, publish);
      formData.append(`serviceID`, serviceID);

      this.task.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });

      this.task.optionsCount = [];

      if (this.task.options && this.task.options.length) {
        for (let i in this.task.options) {
          this.task.optionsCount[this.task.options[i]] = this.getOptionFromService(this.task.options[i]).count;
        }
      }

      this.task.files_private.forEach((file, index) => {
        formData.append(`files_private[${index}]`, file);
      });

      const skillsContainer = document.querySelector('.selectedSkills');
      if (skillsContainer) {
        const skills = skillsContainer.querySelectorAll('button');
        skills.forEach(element => {
          const skillID = element.getAttribute('data-id');
          const level = element.querySelector('.img_level').getAttribute('data-level');
          const res = {
            skillID: skillID,
            level: level
          }
          this.task.selectedSkills.push(res);
        });
      }

      formData.append(`task`, JSON.stringify(this.task));

      try {
        const response = await axios.post(
          this.url + '/create/api?event=saveTask',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        if (response.data.status == 'success') {

          if (publish && this.websocket && this.websocket.connectionStatus) {
            const message = {
              action: "task",
              taskID: response.data.data,
              create: this.task.task_id && !publish ? false : true,
            }
            this.websocket.send(message);
          }

          window.location.href = "/task/" + response.data.data;
        } else {
          this.errorAPI = response.data && response.data.data ? response.data.data : this.getTr('system_error');
        }

      } catch (e) {
        console.log(e);
        return false;
      }
    },

    async removeService(serviceID) {
      this.errorAPI = null;
      try {
        const response = await axios.post(
          this.url + '/create/api?event=removeService',
          {
            data: {
              serviceID: serviceID,
              selectedUserID: this.selectedUserID
            },
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )


        this.clearAllService();

        this.modalRemoveArchive = false;
        this.loadUserServices();
        this.createEvent('Услуга удалена');

      } catch (e) {
        console.log(e);
        return false;
      }

      this.clearAll();
    },
    async removeTask(taskID) {
      this.errorAPI = null;
      try {
        const response = await axios.post(
          this.url + '/create/api?event=removeTask',
          {
            data: {
              taskID: taskID
            },
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        if (response.data.status != 'success') {
          //window.location.href = "/create";
        }

        for (let i in this.archiveTasks) {
          if (this.archiveTasks[i].task_id == taskID) {
            this.archiveTasks.splice(i, 1);
          }
        }

        this.clearAll();

        this.modalRemoveArchive = false;

      } catch (e) {
        console.log(e);
        return false;
      }

      this.clearAll();
    },
    clearAllService() {
      this.selectedUserService = null;
      this.errorAPI = null;
      this.service.service_logo = null;
      this.avatarURL = null;
      this.service.service_id = 0;
      this.service.category_id = 1;
      this.service.additional_information = '';
      this.service.service_included = '';
      this.service.necessary = '';
      this.service.service_name = '';
      this.service.files = [];
      this.service.options = [];
      this.service.days_to_end = 0;
      this.service.price = 0;
      this.modalRemoveArchive = false;
      this.modalClear = false;
      this.showError = false;

      this.original = {};

      this.service.captions_name = {};
      this.service.additional_information_captions = {};
      this.service.service_included_captions = {};
      this.service.necessary_captions = {};

      this.original.additional_information = "";
      this.original.service_included = "";
      this.original.necessary = "";

      this.original.additional_information_captions = {};
      this.original.service_included_captions = {};
      this.original.necessary_captions = {};

    },
    clearAll() {
      return;
      this.errorAPI = null;
      $('#task_skills').val(null).trigger('change');
      $('#serviceOptions').val(null).trigger('change');
      this.task = {
        category_id: 1,
        minutes: null,
        hours: null,
        task_date_to: null,

        skills: [],
        files: [],
        files_private: [],
        options: [],

        task_id: 0,
        user_id: 0,

        task_price_def: 5,

        task_name: '',
        task_description: '',
        task_private: '',

        task_openprice: 0,
        task_price: null,
        task_status: 0,

        level: 1,
        project_id: 0,
      }
      this.createTaskFromService = null;
      this.modalRemoveArchive = false;
      this.modalClear = false;
      this.showError = false;
    },
    setSkillLevel(skillID, level) {
      this.getSkillById(skillID).level = level;
      this.getSkillById(skillID).show_list = false;
    },
    removeOptionFromTask(optionID) {
      $('#serviceOptions option[value="' + optionID + '"]').prop('selected', false).change();
      $(this).parent().remove();
    },
    removeSkillFromTask(skillID) {
      $('#task_skills option[value="' + skillID + '"]').prop('selected', false).change();
      $(this).parent().remove();
    },
    getSkillById(skillID) {
      for (let i in this.skills) {
        if (this.skills[i].skill_id == skillID) return this.skills[i];
      }
    },
    getOptionFromService(optionID) {
      for (let i in this.createTaskFromService.options) {
        if (this.createTaskFromService.options[i].option_id == optionID) return this.createTaskFromService.options[i];
      }
    },
    handleFileInputService(event) {
      this.service.files = this.service.files.concat(Array.from(event.target.files));
    },
    handleFileInput(event) {
      this.task.files = this.task.files.concat(Array.from(event.target.files));
    },
    handleFileInputPrivate(event) {
      this.task.files_private = this.task.files_private.concat(Array.from(event.target.files));
    },
    async connectWebSocket() {
      this.websocket = new WebSocketConnection(WebSocketURL, "loader", 0, 'caption_russian');
      await this.websocket.connect();
      await this.mainActionsWebSocket();
    },
    hideMainLoading() {
      // Прячем загрузку, если она есть на странице
      const main_loading = document.querySelector('.main_loading');
      if (main_loading) main_loading.classList.add('d-none');
      this.canShowError = true;
    },
    async mainFunctionApp() {

      if (!this.websocket || !this.websocket.connectionStatus) await this.connectWebSocket();

      const urlParams = new URLSearchParams(window.location.search);
      const taskID = urlParams.get('taskID');
      const serviceID = urlParams.get('serviceID');
      const issueID = urlParams.get('issueID');
      const stickerID = urlParams.get('stickerID');

      const action = urlParams.get('action');

      if (action == 'createServise') {
        this.tab = 'service';
      }

      await this.loadCaptions();
      await this.loadCategories()
      if (this.selectedUserID) await this.loadUserServices();

      if (taskID) {
        await this.getTask(taskID);
      } else if (serviceID) {
        await this.getService(serviceID);
        if (action != 'createTask') this.tab = 'service';
      } else if (issueID) {
        await this.getTask(0, issueID);
      } else if (stickerID) {
        await this.getTask(0, 0, stickerID);
      }
    },
    checkConnection() {
      // Функция для переподключения к серверу
      // Если в течении 35 сек не было получено event ping
      clearTimeout(this.intervalReconnection);
      this.intervalReconnection = setInterval(async () => {
        this.mainFunctionApp();
      }, "35000");
    },
    async entryRoom() {
      // Подключаемся к комнате
      const connectRoom = {
        action: "connectRoom",
      }
      this.websocket.send(connectRoom)
    },
    connectionClose() {
      if (this.websocket) {
        this.websocket.close();
        this.websocket.connectionStatus = false;
        this.websocket.connection = null;
        this.websocket.connectionRoom = false;
      }
      this.websocket = null;
    },
    listenerOffline() {
      // Пропало соединение с интернетом
      this.connectionClose();
    },
    listenerOnline() {
      // Соединение с интернетом появилось
      this.mainFunctionApp();
    },
    async mainActionsWebSocket() {
      this.websocket.connectionStatus = true;
      this.checkConnection();
      await this.entryRoom();

      this.websocket.connection.onclose = (event) => {
        this.connectionClose();
      };

      // Полученные сообщения от сервера
      this.websocket.connection.onmessage = (message) => {

        const data = JSON.parse(message.data);
        const action = data.action;

        if (action == "error") {
          alert(data.message);
        }

        if (action == "connectRoom") {
          this.websocket.connectionRoom = true;
        }

        if (action == "ping") {
          this.checkConnection();
          const event = { action: "pong" }
          this.websocket.send(event)
        }

        if (action == "createUser") {
          this.errorCreateUser = data.message;
          this.loadCreate = false;
        }

        if (action == "failedEditUser") {
          this.errorCreateUser = data.message;
          this.loadCreate = false;
        }

        if (action == "editUser") {
          this.loadCreate = false;
          this.modalCreateUser = false;

          this.newUser.description = null;
          this.newUser.login = null;
          this.newUser.password = null;
          this.newUser.email = null;
          this.newUser.description = null;
          this.newUser.photoBlob = null;
          this.photoURL = null;

          this.modalEditUser = null;

          console.log(data.user)

          if (data.user) {
            this.user.usersControl = this.replaceObjectById(this.user.usersControl, data.user);
          }

          this.createEvent('Пользователь сохранён');
        }

        if (action == "changePassword") {
          this.newPassword = null;
          this.createEvent('Пароль был изменён');
        }

        if (action == "entry") {
          this.loadingEntry = false;
          const user = data.user;
          if (!user) {
            this.entryError = data.message;
          } else {
            this.websocket.user = user;
            this.login = null;
            this.password = null;
            if (data.entry_key) localStorage.entry_key = data.entry_key
            this.user = user;

            this.mainFunctionAfrerEntry();
          }
        }

        if (action == "changeUser") {
          for (let i in this.user.usersControl) {
            if (this.user.usersControl[i].user_id == data.user.user_id) {
              this.user.usersControl[i] = data.user;
            }
          }
        }

        if (action == "newUser" && this.user.user_id == data.superUserID) {

          const user = data.user;
          this.user.usersControl.push(user);


          this.loadCreate = false;
          this.modalCreateUser = false;

          this.newUser.description = null;
          this.newUser.login = null;
          this.newUser.password = null;
          this.newUser.email = null;
          this.newUser.description = null;
          this.newUser.photoBlob = null;
          this.photoURL = null;

          this.createEvent('Пользователь создан');

        }


      };

    },
    replaceObjectById(array, newObject) {
      return array.map(obj => obj.user_id === newObject.user_id ? newObject : obj);
    },
    async getService(serviceID) {
      try {
        const response = await axios.post(
          this.url + '/create/api?event=getService',
          {
            data: {
              serviceID: serviceID
            },
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        if (response.data.status != 'success') {
          window.location.href = "/create";
        }

        this.loadService(response.data.data);

      } catch (e) {
        console.log(e);
        return false;
      }
    },

    async loadSkills() {
      try {
        const response = await axios.post(
          this.url + '/create/api?event=loadSkills',
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        this.skills = response.data.data;

      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async loadUserPortfolio(userID) {
      try {
        const response = await axios.post(
          this.url + '/create/api?event=loadUserPortfolio&userID=' + userID,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        this.userPortfolio = response.data.userPortfolio;

      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async loadCaptions() {
      try {
        const response = await axios.post(
          this.url + '/create/api?event=loadCaptions',
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        this.captions_task = response.data.captions_task;
        this.captions_services = response.data.captions_services;

      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async loadLevels() {
      try {
        const response = await axios.post(
          this.url + '/create/api?event=loadLevels',
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        this.levels = response.data.data;

      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async loadProjects() {
      try {
        const response = await axios.post(
          this.url + '/create/api?event=loadProjects',
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        this.projects = response.data.data;

      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async loadCategories() {
      try {

        const response = await axios.post(
          this.url + '/create/api?event=loadCategoriesFromLoader',
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        this.categories = response.data.data;

        for (let i in this.categories) {
          this.categories[i].name = this.getTr(this.categories[i].name);
          for (let s in this.categories[i].subs) {
            this.categories[i].subs[s].category_title = this.getTr(this.categories[i].subs[s].category_title);
          }

        }

      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async loadUserServices() {
      try {
        const response = await axios.post(
          this.url + '/create/api?event=loadUserServices',
          {
            data: {
              selectedUserID: this.selectedUserID
            },
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        this.userServices = response.data.data;
        this.userRating = response.data.aUserRaiting;
        this.countClosed = response.data.countClosed;
        this.percentTaskOnTime = response.data.percentTaskOnTime;
        this.percentageClosed = response.data.percentageClosed;

      } catch (e) {
        console.log(e);
        return false;
      }
    },
    createTranslationMap(translations) {
      this.translations = new Map();
      for (const translation of translations) {
        this.translations.set(translation.lang_key, translation);
      }
    },
    getTr(key) {
      return this.translations.has(key)
        ? this.translations.get(key)[this.caption]
        : 'Translation not found: ' + key;
    },
    filterCategorySubs(array) {
      if (!this.searchCategory) return array;
      return array.filter((elem) => ((elem.category_title).toLowerCase()).includes(this.searchCategory.toLowerCase()))
    },
  },
  computed: {
    filterPortfolio() {
      console.log("userPortfolio", this.userPortfolio)
      return this.userPortfolio.filter(elem =>
        ((elem.description).toLowerCase()).includes((this.searchPortfolio).toLocaleLowerCase()) || ((elem.title).toLowerCase()).includes((this.searchPortfolio).toLocaleLowerCase())
      )
    },
    filterUsersInCategory() {
      let count = 0;
      return this.user.usersControl.filter(elem => {
        if (!this.showAllUsers && count >= 20) {
          return false;
        }

        if (!this.filteredCategoryIdForUsers) {
          count++;
          this.showAllUsersCount = count;
          return true;
        }

        for (let i in this.allServices) {
          if (this.allServices[i].category_id == this.filteredCategoryIdForUsers && this.allServices[i].user_id == elem.user_id) {
            count++;
            this.showAllUsersCount = count;
            return true;
          }
        }

        return false
      })
    },
    filterUserServices() {
      if (!this.filterUserCategory) return this.userServices;

      return this.userServices.filter(elem => elem.category_id == this.filterUserCategory);

    },
    filterCategory() {

      if (!this.searchCategory) return this.categories;

      return this.categories.filter((elem) => {

        if (((elem.name).toLowerCase()).includes(this.searchCategory.toLowerCase())) return true;

        for (let i in elem.subs) {
          if (
            ((elem.subs[i].category_title).toLowerCase()).includes(this.searchCategory.toLowerCase())
          ) return true;
        }

        return false;
      })
    },
    blockSaveUser() {
      if (!this.modalEditUser.user_profile_about) return true;
      if (!this.modalEditUser.user_login) return true;
      if (!this.modalEditUser.user_mail) return true;
      if (!this.modalEditUser.fio) return true;

      return false;
    },
    blockCreateUser() {

      if (!this.newUser.login) return true;
      if (!this.newUser.fio) return true;

      return false;
    },
    blockSaved() {

      if (!this.service.service_name) return true;
      if (!this.service.price) return true;

      return false;
    },
    executor_get_sum() {

      const percent_task = this.comission;
      let price = this.task.task_price && this.task.task_price != 0 ? this.task.task_price : this.task.task_price_def;

      if (this.createTaskFromService) {

        for (let i in this.task.options) {
          price = Number(price) + (Number(this.getOptionFromService(this.task.options[i]).option_price) * Number(this.getOptionFromService(this.task.options[i]).count));
        }

      }

      return Number(price) - (Number(price) / 100 * Number(percent_task));

    },

    executor_get_sum_service() {

      const percent_task = this.comission;
      const price = this.service.price;
      return Number(price) - (Number(price) / 100 * Number(percent_task));

    }
  },
  watch: {
    selectedUserID(newVal) {
      if (newVal) {
        this.previewServicePage = null;
        this.loadUserPortfolio(newVal);
        this.loadUserServices();
      }
    },
    selectedUserService(newVal) {
      if (newVal) this.getService(newVal);
    }
  },
}
</script>
<style>
.lost-error {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 99999999999999999999;
  backdrop-filter: blur(11px);
}


.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #d3d3d3;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: rgb(255, 255, 255);
  padding: 20px 30px;
  width: 90%;
  max-width: 300px;
  border-radius: 5px;
  position: relative;
}

.modalContentElem {
  display: flex;
  flex-direction: column;
  font-size: 13px;
}



.modal input,
.modal textarea {
  border-radius: 5px;
  padding: 5px;
  margin-top: 4px;
  border: 1px solid lightgrey;
}

.modal button {
  padding: 8px;
  border: none;
  cursor: pointer;
  transition: all .3s;
  border-radius: 5px;
  margin-top: 10px;
  background: #789fcf;
  color: white;
  font-weight: bold;
}

.modal button:hover {
  opacity: .5;
}

.modalContentLoading {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  backdrop-filter: blur(2px);
  font-weight: bold;
}

.modalContentError {
  font-weight: bold;
  text-align: center;
  color: red;
  font-size: 12px;
}

.disabledBtn {
  opacity: .5;
  pointer-events: none;
}

.pageTitle h3 {
  display: flex;
  gap: 10px;
  align-items: center;
}

.pageTitle h3 img {
  width: 40px;
}

.pageTitle {
  display: flex;
  flex-direction: column;
}

.selectUsers {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
}

.selectUserElem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  transition: all .3s;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
  height: 40px;
  overflow: hidden;
}

.selectUserElem.selectedUser {
  background: #6c91bf;
  color: white;
  font-weight: bold;
}

.btnCreateUser {
  background: #6c91bf;
  color: white;
  font-weight: bold;
  padding: 7px 13px;
  font-size: 13px;
}


.selectUserElem:hover {
  opacity: .5;
}

.selectUserElem img {
  width: 40px;
}

.modalCreateUser {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalCreateUserContent {
  width: 90%;
  max-width: 600px;
  height: 90%;
  min-height: 400px;
  overflow: auto;
  background: gainsboro;
  border-radius: 10px;
  position: relative;
  padding: 10px;
}

.closeModal {
  position: absolute;
  width: 20px;
  right: 10px;
  top: 10px;
  cursor: pointer;
  transition: all .3s;
}

.closeModal:hover {
  opacity: .5;
}

.createProfileElem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin: 10px 0;
}

.createProfileElemPhoto {}

.createProfileElemPhoto img {
  width: 60px;
}

.taskFiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 10px 0;
}

.taskFilesFileImg {
  width: 30px;
  height: 40px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.taskFilesFile {
  display: flex;
  align-items: center;
  margin: 5px;
  padding-right: 25px;
  position: relative;
  overflow: hidden;
}

.taskFilesFileName {
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: fit-content;
  max-width: 140px;
  overflow: hidden;
  margin-left: 10px;
  color: var(--color1);
}

.taskFilesFileDelete {
  position: absolute;
  background: #fff;
  border: 1px solid var(--color1);
  padding: 4px 2px 2px;
  width: 17px;
  height: 17px;
  color: var(--color1);
  font-weight: bold;
  font-size: 12px;
  transition: all .3s;
  align-items: center;
  justify-content: center;
  border-radius: 150px;
  right: 0;
  top: 31%;
  cursor: pointer;
  display: flex;
  pointer-events: none;
  opacity: 0;
}

.taskFilesFile:hover .taskFilesFileDelete {
  pointer-events: all;
  opacity: 1;
}

.events {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 9999999;
}

.eventsElem {
  background: #93939399;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.selectUsersElems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.pageTitleAuth {
  display: flex;
  align-items: center;
  gap: 20px;
}

.serviceOptionContentPriceCourse {
  margin: 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
}

.serviceAvatar {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  border-radius: 10px;
}

.serviceAvatar img {
  width: 100%;
}

.modalContentElem input {
  max-width: 100%;
  min-height: 35px;
  border: none;
  box-shadow: inset 2px 2px 10px rgb(0 0 0 / 15%);
  padding: 7.5px 16px;
  /* font-size: 12px; */
  line-height: 15px;
  color: var(--color1);
  box-sizing: border-box;
  position: relative;
  border-radius: 50px;
  background: #ffffff;
  display: flex;
  align-items: center;
  outline: none;

  border-radius: 5px;
  padding: 5px;
  margin-top: 4px;
  border: 1px solid lightgrey;
}

.showAllUsers {
  width: 100%;
  display: flex;
  background: #e5e5e5;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
</style>